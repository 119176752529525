<template>
  <div class="personal-coupon-center" :style="!isWeixin_status ? 'padding-top: 50px':''">
    <NavBar
      v-if="!isWeixin_status" 
      style="z-index:999"
      fixed title="领券中心" 
      @click-left="goBack" 
      :left-arrow="isWeixin_status == false"
    >
    </NavBar>
    <List
      class="coupon-list"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList()">
      <div class="coupon-item" v-for="(item, idx) in listArr" :key="idx">
        <div class="coupon-item-left">
          <div class="coupon-item-left-img">
            <img :src="item.type | img_Type" alt="">
          </div>
          <div class="coupon-item-center">
            <div class="coupon-item-title">{{item.title}}</div>
            <!--代金券-->
            <div class="end_money" v-if="item.type == 3">
              <span>使用后减</span>
              <span class="end-money-text">{{item.d_face_value}}</span>
            </div>
            <div class="coupon-item-time">
              <template v-if="item.is_exp">该优惠券领取时间已过</template>
              <template v-else><span>  {{item.get_start_time}} 至 {{item.get_end_time}}</span></template>
            </div>
          </div>
        </div>
        <div class="coupon-item-right">
          <!--满减券-->
          <div class="coupon-money" v-if="item.type == 1">{{item.m_dec_money}}</div>
          <!--折扣券-->
          <div class="coupon-zhe" v-if="item.type == 2">{{item.zhe_discount}}</div>
          <!--代金券-->
          <div class="coupon-money" v-if="item.type == 3">{{item.d_face_value}}</div>
          <!--兑换券-->
          <!-- <div class="coupon-money" v-if="item.type == 4">{{item.d_face_value}}</div> -->
          
          <div class="coupon-type">{{item.free_type == 1?'随机':''}}{{item.type | coupon_type}}</div>
          <div v-if="item.is_residue" class="coupon-btn" @click="getTicket(item.id)">
            {{ item.up_limit == item.is_residue ? '立即领取':'继续领取' }}
          </div>
          <div v-else class="coupon-get-btn">已领取</div>
        </div>
        <div class="jindu">
          <div class="jindu-box"><div class="jindu-box-center" :style="'width:' + item.rate + '%'"></div></div>
          <p>剩余{{item.is_residue}}张可领</p>
        </div>
        <div class="get-ticket" :style="item.is_residue == 0 ? bgImg:''"></div>
      </div>
    </List>
  </div>
</template>
<script>
import { NavBar, List } from 'vant';
import CreditShop from "@/api/creditshop";
export default {
  components: {
    // Tabs,
    // Tab,
    NavBar,
    List,
  },
  data() {
    return {
      active: '#96',
      finished: false,
      loading: false,
      tabs: ['#96', '#92', '#98'],
      listArr: [],
      btn_text: "立即领取",
      bgImg: {
        backgroundImage: "url('https://pinduoduoimg.yangkeduo.com//coupons/2018-12-12/coupon_got.png')"
      }
    }
  },
  filters: {
    img_Type: function(val) {
       switch (val) {
        case 1:
          return require('@static/vip/manjian.png');
        case 2:
          return require('@static/vip/zhekou.png');
        case 3:
          return require('@static/vip/daijin.png');
        case 4:
          return require('@static/vip/duihuan.png');
        default:
          break;
      }
    },
    coupon_type: function(val) {
      switch (val) {
        case 1:
          return '满减券'
        case 2:
          return '折扣券'
        case 3:
          return '代金券'
        case 4:
          return '兑换券'
        default:
          break;
      }
    }
  },
  mounted() {
    if (this.isWeixin_status) {
      this.getList();
    }
  },
  methods: {
    async getTicket(id) {
      try {
        const res = await CreditShop.getCompon(id);
        if (res.code == 200) {
          this.$toast(res.msg);
          this.getList();
        } else if (res.code == 400 && res.msg == "开通会员才可以领取该优惠券") {
          this.$dialog
            .confirm({
              title: "领取失败",
              message: res.msg
            })
            .then(() => {
              this.$router.push("/dredge_card");
            })
        } else {
          throw res.msg;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    async getList() {
      try {
        const res = await CreditShop.getComponList(this.page);
        if (res.code == 200) {
          if (res.data.result.length > 0) {
            for (let i in res.data.result) {
              if (Number(res.data.result[i].up_limit) > 0) {
                res.data.result[i].status = true;
              } else {
                res.data.result[i].status = false;
              }
            }
            this.listArr = res.data.result;
            if (res.data.total_num == this.listArr.length) {
              this.finished = true;
            }
          } else {
            throw("暂无数据...")
          }
        } else {
          this.loading = false;
          this.finished = true;
          throw(res.msg);
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
        this.$toast(error);
      }
    },
  }
}
</script>
<style>
.van-nav-bar--fixed {
  z-index: 100;
}
</style>
<style lang="scss" scoped>
.personal-coupon-center {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  .coupon-item {
    width: 94%;
    height: 137px;
    position: relative;
    margin: 10px auto;
    background: url(https://pinduoduoimg.yangkeduo.com/coupons/v2/choiceness_bg_v2.png) no-repeat;
    background-size: 100% 100%;
    .coupon-item-center {
      display: flex;
      padding-left: 6px;
      justify-content: space-between;
      flex-direction: column;
      .coupon-item-title {
        font-size: 16px;
        font-weight: 600;
        color: #FF5706;
      }
      .end_money {
        margin-top: 4px;
        color: #e02e24;
        font-size: 12px;
        line-height: 18px;
        .end-money-text {
          display: inline-block;
          font-size: 18px;
          font-weight: 700;
          margin-left: 4px;
        }
        .end-money-text::before {
          font-size: 12px;
          content: "\A5";
        }
      }
      .coupon-item-time {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
      }
    }
    .jindu {
      position: absolute;
      height: 6PX;
      width: 150px;
      background-color: #ededed;
      border-radius: 3rem;
      bottom: 12.1px;
      left: 12px;
      display: flex;
      .jindu-box {
        width: 100%;
        height: 100%;
      }
      .jindu-box-center {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        min-width: 6px;
        background-color: #e02e24;
        border-radius: 3px;
      }
      p {
        position: absolute;
        top: -6px;
        left: 154px;
        width: 100px;
        font-size: 11px;
        color: #58595b;
       }
    }
    .get-ticket {
      position: absolute;
      top: 0;
      right: -1px;
      width: 52px;
      height: 52px;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 20;
    }
    .coupon-item-left {
      position: absolute;
      top: 12px;
      left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      .coupon-item-left-img {
        position: relative;
        width: 80px;
        height: 80px;
        background-color: #fafafa;
        background-size: contain;
        overflow: hidden;
        border-radius: .02rem;
        img {
          width: 100%;
          max-height: 100%;
        }
      }
    }
    .coupon-item-right {
      position: absolute;
      top: 19px;
      right: 01px;
      width: 94px;
      height: 96px;
      text-align: center;
      z-index: 10;
      color: #e02e24;
      font-size: 13rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      .coupon-money, .coupon-zhe {
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        color: #e02e24;
      } 
      .coupon-type {
        margin-top: 1px;
        margin-bottom: 15px;
        font-size: 13px;
        line-height: 1;
      }
      .coupon-zhe::before {
        position: relative;
        left: 40px;
        font-size: 12px;
        content: "折";
      }
      .coupon-money::before {
        position: relative;
        right: 2px;
        font-size: 12px;
        content: "\A5";
      }
      .coupon-btn,.coupon-get-btn {
        width: 60px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        z-index: 10;
        background: #e02e24;
      }
      .coupon-get-btn {
        background: #fb0;
      }
    }

  }
}
</style>